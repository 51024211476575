import React from "react";

const Footer = () => {
  return (
    <footer id="footer" style={{ backgroundColor: "#ffffff" }}>
      <div id="copyrights" className="bg-color">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              Copyrights &copy; 2023{" "}
              <a
                href="https://www.multicodesolution.com/"
                target="_blank"
                rel="noreferrer"
                style={{ color: "black" }}
              >
                Multicode Solution
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
