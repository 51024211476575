import React, { Fragment, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { imageUri } from "../constant";
import { ImagesSkeleton } from "../utils/helper";

const ReletedImages = (props) => {
  const { arrImges, uri, index, name, load, setLoad } = props;
  const [indexOfImages, setIndexOfImages] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const openModalAndSetIndex = (index) => {
    setIndexOfImages(index);
    setShowModal(true);
  };

  let imgsContent;
  if (arrImges) {
    imgsContent = arrImges.map((elem) => {
      return imageUri + "/" + elem.image;
    });
  }
  return (
    <Fragment>
      <div
        className="col-lg-2 col-md-4 col-6 mb-6 image-div"
        onClick={() => openModalAndSetIndex(index)}
      >
        <div className="team overflow-hidden">
          <div className="team-image">
            <img
              src={uri}
              alt="Penny Tool"
              onLoad={() => setLoad(true)}
              style={{ display: load === true ? "block" : "none" }}
            />
            <ImagesSkeleton load={load} />
          </div>
          <div className="team-desc">
            <h4 className="team-title pt-3 mb-0 fw-medium text-transform-none">
              {name}
            </h4>
          </div>
        </div>
      </div>
      {showModal && (
        <Lightbox
          mainSrc={imgsContent[indexOfImages]}
          nextSrc={imgsContent[(indexOfImages + 1) % imgsContent.length]}
          prevSrc={
            imgsContent[
              (indexOfImages + imgsContent.length - 1) % imgsContent.length
            ]
          }
          onCloseRequest={() => setShowModal(false)}
          onMovePrevRequest={() =>
            setIndexOfImages(
              (indexOfImages + imgsContent.length - 1) % imgsContent.length
            )
          }
          onMoveNextRequest={() =>
            setIndexOfImages((indexOfImages + 1) % imgsContent.length)
          }
        />
      )}
    </Fragment>
  );
};

export default ReletedImages;
