import React, { useContext } from "react";
import Contentheader from "../../reuse/Contentheader";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ApiCallsContext from "../../context/ApisCallsContext/ApiCallsContext";
import { PrivarSabhaSkeleton, YoutubeUrl } from "../../utils/helper";
import moment from "moment";

const ParivarSabha = () => {
  const { parivarShabhaData } = useContext(ApiCallsContext);
  let responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  };
  return (
    <div className="section bg-transparent mt-0 mb-4" id="parivarsabha">
      <Contentheader header="Parivar Sabha" />
      <OwlCarousel
        items={2}
        margin={10}
        autoplay={false}
        nav
        loop
        autoHeight={true}
        responsive={responsive}
        dots
      >
        {parivarShabhaData.length ? (
          parivarShabhaData?.map((elem) => {
            const id = YoutubeUrl(elem?.link);
            return (
              <div>
                <iframe
                  src={`https://www.youtube.com/embed/${id}`}
                  frameBorder="0"
                  width="853"
                  height="300"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="video"
                />
                <div>
                  <p
                    style={{
                      margin: "0",
                      fontWeight: "400",
                      fontSize: "0.875rem",
                      lineHeight: "1.43",
                      letterSpacing: "0.01071em",
                      marginBottom: "0.35rem",
                    }}
                  >
                    {elem.name}
                  </p>
                  <span
                    style={{
                      margin: "0",
                      fontWeight: "400",
                      fontSize: "0.75rem",
                      lineHeight: "1.66",
                      letterSpacing: "0.03333em",
                      display: "block",
                      color: "rgba(0,0,0,0.6)",
                      marginBottom: "0.35rem",
                    }}
                  >
                    {moment(elem.date).format("DD/MM/YYYY")}
                  </span>
                  <span
                    style={{
                      margin: "0",
                      fontWeight: "400",
                      fontSize: "0.75rem",
                      lineHeight: "1.66",
                      letterSpacing: "0.03333em",
                      display: "block",
                      color: "rgba(0,0,0,0.6)",
                      marginTop: "0.35rem",
                    }}
                  >
                    {elem.details}
                  </span>
                </div>
              </div>
            );
          })
        ) : (
          <PrivarSabhaSkeleton />
        )}
      </OwlCarousel>
    </div>
  );
};

export default ParivarSabha;
