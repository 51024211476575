import React, { useContext, useEffect, useState } from "react";
import Contentheader from "../../../reuse/Contentheader";
import AllSatesContext from "../../../context/allStates/AllStatesContext";
import BackImage from "../../../reuse/backButton";
import { useNavigate } from "react-router-dom";
import { PaginationButtons, YoutubeUrl } from "../../../utils/helper";
import { toutubeUrl } from "../../../constant";
import ApiCallsContext from "../../../context/ApisCallsContext/ApiCallsContext";

const Fullvideo = (props) => {
  const { setProgress } = props;
  const { videoAll, subActivityId } = useContext(AllSatesContext);
  const {
    sub_to_sub_Activitys,
    subToSubActivitys,
    perPage,
    setPerPage,
    page,
    setPage,
    totalRecords,
    setTotalRecords,
  } = useContext(ApiCallsContext);
  const [urlSet, setUrlSet] = useState("");

  const openVideo = (url) => {
    const id = YoutubeUrl(url);
    setUrlSet(toutubeUrl + "/" + id);
  };

  const history = useNavigate();

  const handlePrev = () => {
    history("/videos");
  };

  useEffect(() => {
    sub_to_sub_Activitys(
      subActivityId || localStorage.getItem("subActivityId")
    );
    setProgress(100);
    // eslint-disable-next-line
  }, [page]);
  useEffect(() => {
    setPerPage(10);
    setPage(1);
    setTotalRecords(0);
  }, []);

  const nextPage = () => {
    if (page + 1 > Math.ceil(totalRecords / perPage)) {
    } else {
      setPage(page + 1);
    }
  };

  const setUrilFunc = () => {
    if (urlSet) {
      return urlSet;
    } else if (videoAll && !videoAll.data) {
      return videoAll;
    } else {
      return localStorage.getItem("youtubeLink");
    }
  };

  return (
    <div style={{ paddingTop: "20px" }}>
      <Contentheader header={videoAll.name} />
      <BackImage redirect={handlePrev} />
      <div className="row gx-5 mt-5 col-mb-50 mb-0">
        <div className="col-md-12">
          <div
            className="feature-box flex-column ps-0"
            style={{ boxShadow: "0 0 50px #ccc" }}
          >
            <div className="fbox-media position-relative">
              <iframe
                src={setUrilFunc()}
                frameBorder="0"
                width="600"
                height="480"
                autoPlay
                allowFullScreen
                title="video"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="row justify-content-around"
        style={{
          gap: "30px",
          paddingTop: "30px",
        }}
      >
        {subToSubActivitys.length &&
          subToSubActivitys?.map((elem) => {
            const id = YoutubeUrl(elem?.link);
            return (
              <div
                className="col-lg-2 col-md-4 col-6 image-div"
                onClick={() => openVideo(elem.link)}
              >
                <div className="team overflow-hidden">
                  <div className="team-image">
                    <img
                      src={`https://i.ytimg.com/vi/${id}/hqdefault.jpg`}
                      alt="Penny Tool"
                    />
                  </div>
                  <div className="team-desc">
                    <h4
                      className="pt-3 mb-0 fw-medium text-transform-none"
                      style={{ color: "#746e6e", fontSize: "12px" }}
                    >
                      {elem.name}
                    </h4>
                  </div>
                </div>
              </div>
            );
          })}
        <PaginationButtons
          page={page}
          totalRecords={totalRecords}
          perPage={perPage}
          setPage={setPage}
          nextPage={nextPage}
        />
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={{ paddingBottom: "20px" }}>
            <button
              className="button button-rounded button-border text-transform-none ls-0 fw-normal ms-0 mt-4 btn-hover-effect"
              onClick={() => {
                setPage(page - 1);
              }}
              style={{
                color: page <= 1 ? "rgb(149 116 114)" : "#ff3d32",
                borderColor: page <= 1 ? "rgb(149 116 114)" : "#ff3d32",
              }}
              disabled={page <= 1}
            >
              {"Previus"}
            </button>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <button
              className="button button-rounded button-border text-transform-none ls-0 fw-normal ms-0 mt-4 success-btn-hover-effect"
              onClick={() => {
                nextPage();
              }}
              style={{
                color:
                  page + 1 > Math.ceil(totalRecords / perPage)
                    ? "rgb(114 160 131 / 93%)"
                    : "#22c55eed",
                borderColor:
                  page + 1 > Math.ceil(totalRecords / perPage)
                    ? "rgb(114 160 131 / 93%)"
                    : "#22c55e",
              }}
              disabled={page + 1 > Math.ceil(totalRecords / perPage)}
            >
              {"Next"}
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Fullvideo;
