import './css/style.css';
import './css/animate.css';
import './css/calendar.css';
import './css/custom.css';
import './css/font-icons.css';
import './css/magnific-popup.css';
import './css/nonprofit.css';
import './App.css';
import 'primeicons/primeicons.css';
// import "./css/noneed.css";
// import "./css/swiper.css";
// import "./css/business.css";
import Navbar from './Components/Navbar/Navbar';
import { Fragment, useState } from 'react';
import ContactUs from './Components/ContactUs/ContactUs';
import Sidemenu from './Components/Sidemenu/Sidemenu';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeComp from './Components/homeComp/HomeComp';
import MoreImages from './Components/Activitis/moreImages/MoreImages';
import Footer from './Components/Footer/Footer';
import SliderSection from './Components/Slider/Slider';
import OurSaint from './Components/ourSaint/OurSaint';
import EventsReletedImages from './Components/EventsSlider/EventsReletedImages';
import AllStates from './context/allStates/AllStates';
import ReletedMoreImages from './Components/Activitis/moreImages/ReletedMoreImages';
import Video from './Components/Activitis/VideoSection/Video';
import Fullvideo from './Components/Activitis/VideoSection/Fullvideo';
import Opening from './Components/opening/opening';
import LoadingBar from 'react-top-loading-bar';
import ApiCallsState from './context/ApisCallsContext/ApiCallsState';
import ScrollToTop from './utils/scrollToTop';
import Loader from './utils/Loader';

function App() {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  return (
    <Fragment>
      <Router>
        <ScrollToTop />
        <ApiCallsState>
          <AllStates>
            <Loader />
            <Opening setOpen={setOpen} open={open} />
            <Fragment>
              <Navbar />
              <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
              <SliderSection />
              <section id="content">
                <div className="content-wrap py-0" style={{ overflow: 'visible' }}>
                  <div className="container">
                    {/* <ThreeItems /> */}

                    <Routes>
                      <Route path="/" element={<HomeComp setProgress={setProgress} />} />
                      <Route path="/gallery" element={<MoreImages setProgress={setProgress} />} />
                      <Route path="/videos" element={<Video setProgress={setProgress} />} />
                      <Route path="/gallery/images" element={<ReletedMoreImages setProgress={setProgress} />} />
                      <Route path="/videos/gallery" element={<Fullvideo setProgress={setProgress} />} />
                      <Route path="/events" element={<EventsReletedImages setProgress={setProgress} />} />
                      <Route path="/our-saint" element={<OurSaint setProgress={setProgress} />} />
                    </Routes>

                    <ContactUs />
                    <Sidemenu />
                  </div>
                </div>
              </section>
              <Footer />
            </Fragment>
          </AllStates>
        </ApiCallsState>
      </Router>
    </Fragment>
  );
}

export default App;
