import React, { useContext, useEffect, useState } from "react";
import AllSatesContext from "../../context/allStates/AllStatesContext";
import Contentheader from "../../reuse/Contentheader";
import ReletedImages from "../../reuse/ReletedImages";
import { imageUri } from "../../constant";
import { useNavigate } from "react-router-dom";
import BackImage from "../../reuse/backButton";
import ApiCallsContext from "../../context/ApisCallsContext/ApiCallsContext";
import { PaginationButtons } from "../../utils/helper";

const EventsReletedImages = (props) => {
  const { setProgress } = props;
  const { EventsName, eventMasterId } = useContext(AllSatesContext);
  const { Events_Api, eventData, perPage, page, setPage, totalRecords } =
    useContext(ApiCallsContext);

  const history = useNavigate();
  const [load, setLoad] = useState(false);

  const handlePrev = () => {
    history("/");
  };
  useEffect(() => {
    Events_Api(eventMasterId || localStorage.getItem("eventMasterId"));
    setProgress(100);
    // eslint-disable-next-line
  }, [page]);

  const nextPage = () => {
    if (page + 1 > Math.ceil(totalRecords / perPage)) {
    } else {
      setPage(page + 1);
    }
  };
  return (
    <div style={{ paddingTop: "12px" }}>
      <Contentheader header={EventsName} />
      <BackImage redirect={handlePrev} />
      <div className="row justify-content-around" style={{ gap: "35px" }}>
        {eventData.map((elem, index) => {
          return (
            <ReletedImages
              arrImges={eventData}
              uri={imageUri + "/" + elem.image}
              index={index}
              name={elem.name}
              load={load}
              setLoad={setLoad}
            />
          );
        })}
      </div>
      <PaginationButtons
        page={page}
        totalRecords={totalRecords}
        perPage={perPage}
        setPage={setPage}
        nextPage={nextPage}
      />
    </div>
  );
};

export default EventsReletedImages;
