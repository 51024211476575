export const SERVICES = {
  BAGASARADHAM: "/bagasaraDham",
  _PUBLIC: "/bagasaraDham/public",
};

export const V1 = "/api/v1";

export const DESHBOARD = `${SERVICES._PUBLIC}${V1}/dashboard`;
export const DAILY_DARSHAN = `${SERVICES._PUBLIC}${V1}/dailyDarshan`;
export const PARIVAR_SABHA = `${SERVICES._PUBLIC}${V1}/parivarSabha`;
export const EVENT_API = `${SERVICES._PUBLIC}${V1}/event`;
export const ACTIVITYS_MASTER_API = `${SERVICES._PUBLIC}${V1}/activity/master`;
export const ACTIVITYS_API = `${SERVICES._PUBLIC}${V1}/activity`;
export const SUB_ACTIVITYS_API = `${SERVICES._PUBLIC}${V1}/activity/sub`;
export const EVNTES_API = `${SERVICES._PUBLIC}${V1}/event`;
export const SUB_TO_SUB_ACTIVITYS_API = `${SERVICES._PUBLIC}${V1}/activity/subTo`;
