import React, { useContext } from "react";
import ApiCallsContext from "../context/ApisCallsContext/ApiCallsContext";

const Loader = (props) => {
  const { showLoader } = useContext(ApiCallsContext);

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    left: 0,
    top: 0,
    height: "100vh",
    zIndex: "9999999999",
    width: "100%",
    backgroundColor: "rgb(161 121 71 / 29%)",
  };
  if (showLoader) {
    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.height = null;
    document.body.style.overflow = null;
  }
  return (
    showLoader && (
      <div className="loader" style={style}>
        <img src={require("../image/output_XNKB9D.gif")} alt="" />
      </div>
    )
  );
};

export default Loader;
