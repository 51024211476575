import React from 'react';
import svg_divider1 from '../nonprofit/images/divider-1.svg';

const Contentheader = (props) => {
  const { header, p_graph } = props;
  return (
    <div className="row justify-content-center mb-5 pt-5">
      <div className="col-md-7 text-center">
        <div className="heading-block border-bottom-0" style={{ margin: '0' }}>
          <h2 className=" text-transform-none">{header}</h2>
        </div>
        <div className="svg-line mb-4">
          <img src={svg_divider1} alt="svg divider" height="20" />
        </div>
        <p>{p_graph ? p_graph : ''}</p>
      </div>
    </div>
  );
};

export default Contentheader;
