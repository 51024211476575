import React, { useContext, useEffect, useState } from "react";
import AllSatesContext from "../../../context/allStates/AllStatesContext";
import ReletedImages from "../../../reuse/ReletedImages";
import { imageUri } from "../../../constant";
import Contentheader from "../../../reuse/Contentheader";
import BackImage from "../../../reuse/backButton";
import { useNavigate } from "react-router-dom";
import ApiCallsContext from "../../../context/ApisCallsContext/ApiCallsContext";
import { PaginationButtons } from "../../../utils/helper";

const ReletedMoreImages = (props) => {
  const { setProgress } = props;
  const { EventsName, subActivityId } = useContext(AllSatesContext);
  const {
    sub_to_sub_Activitys,
    subToSubActivitys,
    perPage,
    page,
    setPage,
    totalRecords,
  } = useContext(ApiCallsContext);

  const [load, setLoad] = useState(false);
  const history = useNavigate();
  const RedirectPrev = () => {
    history("/gallery");
  };
  useEffect(() => {
    sub_to_sub_Activitys(
      subActivityId || localStorage.getItem("subActivityId")
    );
    // setLoad(false);
    setProgress(100);
    // eslint-disable-next-line
  }, [page]);

  const nextPage = () => {
    if (page + 1 > Math.ceil(totalRecords / perPage)) {
    } else {
      setPage(page + 1);
    }
  };
  return (
    <div style={{ paddingTop: "12px" }}>
      <Contentheader header={EventsName} />
      <BackImage redirect={RedirectPrev} />
      <div className="row justify-content-around" style={{ gap: "35px" }}>
        {subToSubActivitys?.map((elem, index) => {
          return (
            <ReletedImages
              arrImges={subToSubActivitys}
              uri={imageUri + "/" + elem.image}
              index={index}
              name={elem.name}
              load={load}
              setLoad={setLoad}
            />
          );
        })}
      </div>
      <PaginationButtons
        page={page}
        totalRecords={totalRecords}
        perPage={perPage}
        setPage={setPage}
        nextPage={nextPage}
      />
    </div>
  );
};

export default ReletedMoreImages;
