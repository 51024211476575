import React, { useContext } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Contentheader from '../../reuse/Contentheader';
import { imageUri } from '../../constant';
import { useNavigate } from 'react-router-dom';
import AllSatesContext from '../../context/allStates/AllStatesContext';
import ApiCallsContext from '../../context/ApisCallsContext/ApiCallsContext';
import { EventSkeleton } from '../../utils/helper';

const Events = () => {
  const { setEventMasterId } = useContext(AllSatesContext);
  const { eventMaster } = useContext(ApiCallsContext);
  let responsive = {
    0: {
      items: 1
    },
    450: {
      items: 2
    },
    600: {
      items: 3
    },
    1000: {
      items: 4
    }
  };

  const history = useNavigate();

  const EventsImagesPage = (eventMasterId) => {
    setEventMasterId(eventMasterId);
    localStorage.setItem('eventMasterId', eventMasterId);
    /** comment */
    history('/events');
  };
  return (
    <div className="section bg-transparent mt-0 mb-4" id="event">
      <div className="container">
        <Contentheader header="Events" />
        <OwlCarousel items={4} margin={10} autoplay={true} nav loop autoHeight={true} responsive={responsive}>
          {eventMaster?.eventMaster?.length ? (
            eventMaster?.eventMaster?.map((elem) => {
              return (
                elem.type === 1 && (
                  <div key={elem.templeId}>
                    <div style={{ height: '210px', maxHeight: '207.5px' }}>
                      <img className="img" src={imageUri + '/' + elem.image} alt={elem.alt} />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '12px'
                      }}
                    >
                      <button
                        className="button button-rounded button-border text-transform-none ls-0 fw-normal ms-0 mt-4"
                        onClick={() => {
                          EventsImagesPage(elem.eventMasterId);
                        }}
                      >
                        {elem.name}
                      </button>
                    </div>
                  </div>
                )
              );
            })
          ) : (
            <EventSkeleton />
          )}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Events;
