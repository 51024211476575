/**Gallary */
import { useLocation } from "react-router-dom";
import gallryimg1 from "../constant/Gallary-img/1.jpg";
import gallryimg2 from "../constant/Gallary-img/2.jpg";
import gallryimg3 from "../constant/Gallary-img/3.jpg";
import gallryimg4 from "../constant/Gallary-img/4.jpg";
import gallryimg5 from "../constant/Gallary-img/5.jpg";

import reletedimg1 from "../constant/Gallary-img/more/1.jpg";
import reletedimg2 from "../constant/Gallary-img/more/2.jpg";
import reletedimg3 from "../constant/Gallary-img/more/3.jpg";
import reletedimg4 from "../constant/Gallary-img/more/4.jpg";
import reletedimg5 from "../constant/Gallary-img/more/5.jpg";
import reletedimg6 from "../constant/Gallary-img/more/6.jpg";
import reletedimg7 from "../constant/Gallary-img/more/7.jpg";
import reletedimg8 from "../constant/Gallary-img/more/8.jpg";
import React from "react";

export const imageUri =
  "https://mcode.fra1.digitaloceanspaces.com/bagasara-dham";
export const ImagesArr = [
  {
    src: imageUri + "/dailyDarsan/71131683277447597.jpeg",
  },

  {
    src: imageUri + "/dailyDarsan/93521683277530986.jpeg",
  },
  {
    src: imageUri + "/dailyDarsan/17961683277551512.jpeg",
  },
  {
    src: imageUri + "/dailyDarsan/16821683277498651.jpeg",
  },
  {
    src: imageUri + "/dailyDarsan/94511683277578949.jpeg",
  },
];

export default function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/** Slider Image Array */

export const SliderImage = [
  {
    src: "https://mcode.fra1.digitaloceanspaces.com/bagasara-dham/slider/38801683117821719.jpg",
  },
  {
    src: "https://mcode.fra1.digitaloceanspaces.com/bagasara-dham/slider/55751683117838181.jpg",
  },
  {
    src: "https://mcode.fra1.digitaloceanspaces.com/bagasara-dham/slider/82141683117852718.jpg",
  },
  {
    src: "https://mcode.fra1.digitaloceanspaces.com/bagasara-dham/slider/38901683127619716.jpeg",
  },
];

/**More Images array */

export const ButtonArray = [
  {
    button: 2022,
  },
  {
    button: 2023,
  },
];

export const MoreImagesUrls = [
  {
    id: 1,
    url: gallryimg1,
    name: "Patotsav 13",
    case: "patotsav",
  },
  { id: 2, url: gallryimg2, name: "Hari Jayanti", case: "hari_jayanti" },
  { id: 3, url: gallryimg3, name: "Shashtipurti Utsav", case: "shashtipurti" },
  {
    id: 4,
    url: gallryimg4,
    name: "Yuva Adhiveshon - 8",
    case: "yuva_adhiveshon",
  },
  { id: 5, url: gallryimg5, name: "Holi", case: "holi" },
];

/** releted images */

export const ReletedImagesUrls = [
  {
    url: reletedimg1,
  },
  {
    url: reletedimg2,
  },
  {
    url: reletedimg3,
  },
  {
    url: reletedimg4,
  },
  {
    url: reletedimg5,
  },
  {
    url: reletedimg6,
  },
  {
    url: reletedimg7,
  },
  {
    url: reletedimg8,
  },
];

/**Parivar Sabha */
export const toutubeUrl = "https://www.youtube.com/embed";

export const ParivarSabhaUrl = [
  {
    url: toutubeUrl + "/dZZ9YbDYM6I",
  },
  {
    url: toutubeUrl + "/2cDvuzv-0RA",
  },
  {
    url: toutubeUrl + "/o_LgwNENuvU",
  },
  {
    url: toutubeUrl + "/2IgwK_5m_7c",
  },
  {
    url: toutubeUrl + "/VaKSlNMZ5qc",
  },
];

/**Events Slider Images */

export const EventsImages = [
  {
    id: 1,
    url: imageUri + "/events/18871683125583219.jpeg",
    name: "Divya Sakotsav 2023",
  },
  {
    id: 2,
    url: imageUri + "/events/80541683125565944.jpeg",
    name: "Padmashila 2022",
  },
  {
    id: 3,
    url: imageUri + "/events/95031683125539665.jpeg",
    name: "Ghar Sabha",
  },
  {
    id: 4,
    url: imageUri + "/events/93581683125473315.jpeg",
    name: "Sadguru Vandana Mahotsav",
  },
];

/** Video Button  */

export const VideoBurtton = [
  {
    id: 1,
    name: "Katha",
    isActive: false,
  },
  {
    id: 2,
    name: "Kirtan",
    isActive: false,
  },
  {
    id: 3,
    name: "Dhun",
    isActive: false,
  },
  {
    id: 1,
    name: "Other",
    isActive: false,
  },
];

export const KathaImageName = [
  {
    src: "https://i.ytimg.com/vi/A9mVJwGCarE/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLATMSVGP0XtVER9kncyM7W78TpinQ",
    name: "Shree bagavat katha Parayan",
    url: toutubeUrl + "/A9mVJwGCarE",
  },
];

export const KirtanImageName = [
  {
    src: "	https://i.ytimg.com/vi/JfyCLYVU1T0/hqdefault.jpg?s…AFwAcABBg==&rs=AOn4CLDuM3VXo7rBCUpjhu6-p0wYEpF5Ew",
    name: "Shyam Padhro Ghanashyam Padharo",
    url: toutubeUrl + "/JfyCLYVU1T0",
  },
  {
    src: "	https://i.ytimg.com/vi/2uMH3b5r7OU/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBAUToD0WVUPz7i1tRozBTd9FKEXg",
    name: "Kyare Have Malasu Re Mara Solariyaji Sukhadenane",
    url: toutubeUrl + "/2uMH3b5r7OU",
  },
];
export const DhunImageName = [
  {
    src: "https://i.ytimg.com/vi/zcDue4KNnAQ/hqdefault.jpg?s…AFwAcABBg==&rs=AOn4CLCCc4HnqxEL5DLgtKqMFWAxeEQEWA",
    name: " Divy Satsang Shibir",
    url: toutubeUrl + "/zcDue4KNnAQ",
  },
  {
    src: "https://i.ytimg.com/vi/a31znh8C02c/hqdefault.jpg?s…AFwAcABBg==&rs=AOn4CLD37a-MjJA-8Dt4n3A6wBMASMwJZQ",
    name: "Swaminarayan Dhun",
    url: toutubeUrl + "/a31znh8C02c",
  },
];
export const OtherImageName = [
  {
    src: "	https://i.ytimg.com/vi/iWYa51nydA0/hqdefault.jpg?s…AFwAcABBg==&rs=AOn4CLDIRDrHf5MSqn6eiGYQOok8Qeszzg",
    name: "Bhagavad Gita Jayanti hinduism Swaminarayan",
    url: toutubeUrl + "/iWYa51nydA0",
  },
  {
    src: "	https://i.ytimg.com/vi/Fwy8Z1YEcdo/hqdefault.jpg?s…AFwAcABBg==&rs=AOn4CLBMds24eyQhtF3PoW-cDgexb1wl3A",
    name: "Vachanamrut 01",
    url: toutubeUrl + "/Fwy8Z1YEcdo",
  },
];

export const OurSaintImagesAndName = [
  {
    name: "Shree Gunatitanand Swami",
    para: `Gunatitanand Swami was born as Mulji Sharma in the town of Bhadra in
    Gujarat. His father was Bholanath and his mother was Sakarba. From
    his childhood, he had the ability to see what Bhagwan Swaminarayan
    was doing in Chhapaiya, in North India with his inner eyes. In
    Samvat Year 1865, after receiving the divine vision and inspiration
    of Bhagwan Swaminarayan, Gunatitanand Swami left worldly attachments
    behind and renounced his home instantly. He was initiated as a saint
    by Bhagwan Swaminarayan in the Samvat Year 1866 during the Dabhan
    ceremonial sacrifice. He was then given the unique name Gunatitanand
    Swami. Gunatitanand Swami possessed all of the saintly virtues. He
    lived a very simple but highly spiritual life, full of service.
    Bhagwan Swaminarayan was highly pleased with him because of his ever
    readiness to serve the sick saints, thus setting an ideal example
    for others. Later on, he was appointed as the head of the Junagadh
    Temple in Samvat Year 1841 by Bhagwan Swaminarayan.`,
    src: imageUri + "/our_saint/34131683278514887.jpg",
  },
  {
    name: "Shree Balmukunddasji Swami",
    para: `Sadguru Swami Balmukunddasji, the disciple of Sadguru Gunatitanand
    Swami was also a powerful saint, having extraordinary divinity. He
    was born in the village ‘Kalej’ in Saurashtra on the third day of
    Chaitra in the V.S. 1887. His name was Gordhanbhai. From the
    childhood, he had an inclination towards devotion to God. Coming in
    contact with Gunatitanand Swami, there was the inner inspiration to
    renounce the home. Soon after his initiation as a saint in V.S.
    1909, Yogiraj Gopalanand Swami blessed him and forecasted that he
    would be highly capable to shoulder the responsibility of it's of
    sampradaya. Acharya Maharaj Shree of Vadtal had high regards for
    Swamiji on account of his extraordinary calibre. He had undergone
    severe penance and was strictly following the Code of Conduct for
    Saints as prescribed by Bhagwan Swaminarayan. He had unshakeable
    faith in Bhagwan Swaminarayan as the Supreme Lord.`,
    src: imageUri + "/our_saint/99871683278606228.png",
  },
  {
    name: "Shree Hariswarupdasji Swami",
    para: `Hariswarupdasji Swamiji possessed sweet persona, with a pleasant
    behaviour, along with it Swamiji had a deep knowledge about the
    Sanskrit language. Many saints took lessons of Sanskrit from Swamiji
    and people from far distant villages used to visit Swamiji just to
    listen to his sermons and preachings. The teachings of Swamiji
    touched the hearts of the people. Swamiji in his preachings used to
    describe God in a very splendid way. In people used to feel heavenly
    pleasures, the presence of Swamiji and his sermons. Hon’able Swamiji
    dedicated his entire life in the service of ‘Swaminarayan and His
    Saints and devotees,’ and because of his immense devotion towards
    Lord Swaminarayan, Swamiji experienced many miracles in his life. It
    so happened that once in the month of hot summer, Swamiji Hariswarup
    Dasji along with devotees started his journey for Mansa_(a village
    of Saurashtra), and on the way, they kept on singing Holy Choirs of
    Swaminarayan.`,
    src: imageUri + "/our_saint/79421683278637947.jpg",
  },
  {
    name: "Shree Harijivandasji Swami",
    para: ` Pujya Harijivan Swami was born on Vikram Samvat 1972 at
    ajsamadhiyala a village of Saurashtra in ajkot district. His
    father’s name was Arjanbhai and mother’s name was Kankuben. He had a
    keen friendship with God since his childhood. Going to temple for
    Darshan, reciting the prayer and religious rhymes at school and
    meditating in his free time when others his peers were busy in
    sports and games were some of the gods blessed spiritual traits
    could easily be enticed in him. With God’s wish, his father died in
    his childhood and he and his mother settled at his maternal uncle’s
    home. His maternal uncle’s name was Manjibhai who is the follower of
    Lord Swaminarayan. Being a true disciple of Swaminarayan, the Saints
    had occasionally visited his home. Naturally, Devji’s very
    devotional soul awaked in the spiritual and holy ambience of his
    maternal uncle. Meanwhile, he met Pujya Shree Hariswarup Dasji Swami
    and engaged in his service.`,
    src: imageUri + "/our_saint/35381683278663918.jpg",
  },
];

export const type = {
  Video: 1,
  Image: 2,
};

export const Temple = {
  Bagasara: 1,
  Khopala: 2,
};

export const Activites = [
  {
    name: "Videos",
  },
  {
    name: "Mantra Lekhan",
  },
  {
    name: "Audio",
  },
  {
    name: "Photos",
  },
  {
    name: "Download",
  },
];
