import React from "react";
import "../../App.css";

const Sidemenu = () => {
  return (
    <div className="main-sidemenu-div" style={{ width: "285px" }}>
      <ul>
        <li>
          <a
            href="https://www.facebook.com/Bagasarakatha"
            target="_blank"
            rel="noreferrer"
          >
            <img src={require("./FB.png")} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/tirthdham_bagasara/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={require("./Insta.png")} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/@BagasaraKatha"
            target="_blank"
            rel="noreferrer"
          >
            <img src={require("./Youtube.png")} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://api.whatsapp.com/send/?phone=919930072300&text=Jay Swaminarayan"
            target="_blank"
            rel="noreferrer"
          >
            <img src={require("./Whatsapp.png")} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://chat.whatsapp.com/HbRxb8QiUt7F9cNOTG5xRJ"
            target="_blank"
            rel="noreferrer"
          >
            <img src={require("./Community.png")} alt="" />
          </a>
        </li>
        <li>
          <a href="https://t.me/bagasarakatha" target="_blank" rel="noreferrer">
            <img src={require("./Telegram.png")} alt="" />
          </a>
        </li>
        {/* <li>
          <a href="#">
            <img src="	http://www.swaminarayansurat.com/Donate.png" alt="" />
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidemenu;
