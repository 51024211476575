import React, { useContext, useState } from 'react';
import Contentheader from '../../reuse/Contentheader';
import ApiCallsContext from '../../context/ApisCallsContext/ApiCallsContext';
import { Temple, type } from '../../constant';
import { AartiSkeleton, YoutubeUrl } from '../../utils/helper';
import moment from 'moment';

const Aarti = () => {
  const { liveDarshanData } = useContext(ApiCallsContext);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);

  const handleOnLoad = () => {
    setLoading(false);
  };
  const handleOnLoad1 = () => {
    setLoading1(false);
  };
  return (
    <div
      className="section mt-3"
      style={{
        background: "#FFF url('../../nonprofit/images/others/1.jpg') no-repeat 100% 50% / auto 100%",
        paddingBottom: '0'
      }}
      id="aarti"
    >
      <div className="container">
        <Contentheader header="Documentary / Bagasara" />
        <div className="fbox-media position-relative" style={{ padding: '0' }}>
          {liveDarshanData?.liveDarshan?.map((elem) => {
            const id = YoutubeUrl(elem?.link);
            return (
              elem.templeId === Temple.Bagasara &&
              elem.type === type.Video && (
                <>
                  <div
                    style={{
                      display: loading1 ? 'none' : 'flex',
                      justifyContent: 'space-around',
                      marginBottom: '20px'
                    }}
                    className="heading-block border-bottom-0"
                  >
                    <h2 className="text-transform-none" style={{ fontSize: '25px', letterSpacing: '1px' }}>
                      {elem.name}
                    </h2>
                    <h2 className="text-transform-none" style={{ fontSize: '25px', letterSpacing: '1px' }}>
                      {moment(elem.date).format('DD/MM/YYYY')}
                    </h2>
                  </div>

                  <iframe
                    src={`https://www.youtube.com/embed/${id}`}
                    frameBorder="0"
                    width="853"
                    height="480"
                    allow="autoplay"
                    allowFullScreen
                    title="video"
                    onLoad={handleOnLoad1}
                    style={{ display: loading1 ? 'none' : 'block' }}
                  />
                </>
              )
            );
          })}
          <div>{loading1 && <AartiSkeleton />}</div>
        </div>

        <div style={{ paddingTop: '35px' }}>
          <Contentheader header="Live Darshan / Khopala" />
        </div>
        <div className="row gx-5 mt-5 col-mb-50 mb-0">
          <div className="col-md-12">
            <div className="feature-box flex-column ps-0">
              <div className="fbox-media position-relative">
                {liveDarshanData?.liveDarshan?.map((elem) => {
                  const id = YoutubeUrl(elem?.link);
                  return (
                    elem.templeId === Temple.Khopala &&
                    elem.type === type.Video && (
                      <>
                        <div
                          style={{
                            display: loading ? 'none' : 'flex',
                            justifyContent: 'space-around',
                            marginBottom: '20px'
                          }}
                          className="heading-block border-bottom-0"
                        >
                          <h2 className="text-transform-none" style={{ fontSize: '25px', letterSpacing: '1px' }}>
                            {elem.name}
                          </h2>
                          <h2 className="text-transform-none" style={{ fontSize: '25px', letterSpacing: '1px' }}>
                            {moment(elem.date).format('DD/MM/YYYY')}
                          </h2>
                        </div>
                        <iframe
                          // src={`https://www.youtube.com/embed/${id}?rel=0&autoplay=1`}
                          src={`https://www.youtube.com/embed/${id}?rel=0&autoplay=1`}
                          frameBorder="0"
                          width="853"
                          height="480"
                          allow="autoplay"
                          autoPlay
                          allowFullScreen
                          title="video"
                          onLoad={handleOnLoad}
                          style={{ display: loading ? 'none' : 'block' }}
                        />
                      </>
                    )
                  );
                })}
                <div>{loading && <AartiSkeleton />}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aarti;
