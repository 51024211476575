import React, { useState } from "react";
import { Link, animateScroll } from "react-scroll";
import { Link as RedirectLink, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const openAndCloseMenu = () => {
    isOpenMenu === false ? setIsOpenMenu(true) : setIsOpenMenu(false);
  };
  return (
    <header
      id="header"
      className="header-size-sm border-bottom-0"
      data-sticky-shrink="false"
    >
      <div id="header-wrap">
        {/* <div className="container"> */}
        <div
          className="header-row justify-content-lg-between"
          style={isOpenMenu ? { display: "block" } : { display: "flex" }}
        >
          <div id="logo" className="me-lg-5">
            <RedirectLink to="/">
              <img
                className="logo-default"
                srcSet={
                  (require("../../image/gif3.gif"),
                  require("../../image/gif3.gif"))
                }
                src={require("../../image/gif3.gif")}
                alt="Canvas Logo"
                // style={{ width: "23.6rem" }}
              />
              <img
                className="logo-dark"
                srcSet={
                  (require("../../css/images/logo-dark.png"),
                  require("../../css/images/logo-dark@2x.png"))
                }
                src={require("../../css/images/logo-dark@2x.png")}
                alt="Canvas Logo"
              />
            </RedirectLink>
          </div>

          {/* <div className="header-misc">
              <a
                href="demo-nonprofit-causes-single.html"
                className="button button-rounded button-light"
              >
                <div>Donate</div>
              </a>
            </div> */}

          <div
            className={`primary-menu-trigger ${
              isOpenMenu ? "primary-menu-trigger-active" : ""
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => openAndCloseMenu()}
          >
            <button
              className="cnvs-hamburger"
              type="button"
              title="Open Mobile Menu"
            >
              <span className="cnvs-hamburger-box">
                <span className="cnvs-hamburger-inner"></span>
              </span>
            </button>
            {/* <i className="pi pi-align-justify"></i> */}
          </div>

          <nav
            className={`primary-menu with-arrows me-lg-auto nav-menu ${
              isOpenMenu
                ? "primary-menu-active nav-display-render-open"
                : "nav-display-render-close"
            }`}
          >
            <ul
              className="menu-container align-self-start"
              style={
                isOpenMenu
                  ? { flexDirection: "column", alignItems: "normal" }
                  : { flexDirection: "row" }
              }
            >
              <li className="menu-item">
                <span className="menu-bg col-auto align-self-start d-flex"></span>
              </li>
              <li className="menu-item">
                <RedirectLink className="menu-link" to="/" smooth spy>
                  <div>Home</div>
                </RedirectLink>
              </li>
              <li className="menu-item">
                <ScrollToRedirectLink
                  to="/"
                  smooth={true}
                  dureation={500}
                  offset={-100}
                  location="aarti"
                >
                  <div>Aarti</div>
                </ScrollToRedirectLink>
              </li>
              <li className="menu-item">
                <ScrollToRedirectLink
                  to="/"
                  smooth={true}
                  dureation={500}
                  offset={-100}
                  location="daily-darshan"
                >
                  <div>Daily Darshan</div>
                </ScrollToRedirectLink>
              </li>
              <li className="menu-item">
                <ScrollToRedirectLink
                  to="/"
                  smooth={true}
                  dureation={500}
                  offset={-100}
                  location="parivarsabha"
                >
                  <div>Parivar Sabha</div>
                </ScrollToRedirectLink>
              </li>
              <li className="menu-item">
                <ScrollToRedirectLink
                  to="/"
                  smooth={true}
                  dureation={500}
                  offset={-100}
                  location="event"
                >
                  <div>Events</div>
                </ScrollToRedirectLink>
              </li>
              <li className="menu-item">
                <RedirectLink className="menu-link" to="/our-saint">
                  <div>Our Saint</div>
                </RedirectLink>
              </li>

              <li className="menu-item">
                <ScrollToRedirectLink
                  to="/"
                  smooth={true}
                  dureation={500}
                  offset={-100}
                  location="about"
                >
                  <div>About Us</div>
                </ScrollToRedirectLink>
              </li>
              <li className="menu-item">
                <ScrollToRedirectLink
                  to="/"
                  smooth={true}
                  dureation={500}
                  offset={-100}
                  location="activitis"
                >
                  <div>Activitis</div>
                </ScrollToRedirectLink>
              </li>
              <li className="menu-item">
                <ScrollToRedirectLink
                  to="/"
                  smooth={true}
                  dureation={500}
                  offset={-100}
                  location="contact"
                >
                  <div>Contact Us</div>
                </ScrollToRedirectLink>
              </li>
              {/* <li className="menu-item">
                  <a className="menu-link" href="demo-nonprofit-causes.html">
                    <div>Causes</div>
                  </a>
                  <ul className="sub-menu-container">
                    <li className="menu-item">
                      <a
                        className="menu-link"
                        href="demo-nonprofit-causes-single.html"
                      >
                        <div>Single Page</div>
                      </a>
                    </li>
                  </ul>
                </li> */}
            </ul>
          </nav>
        </div>
      </div>
      {/* </div> */}
      <div className="header-wrap-clone"></div>
    </header>
  );
};

export default Navbar;

export const ScrollToRedirectLink = ({
  to,
  smooth,
  duration,
  offset,
  spy,
  activeClass,
  children,
  location,
  ...rest
}) => {
  const history = useNavigate();
  const handleClick = () => {
    history("/");
    if (smooth) {
      animateScroll.scrollTo(location, {
        duration,
      });
    }
  };
  return (
    <Link
      to={location}
      // to={""}
      smooth={smooth}
      duration={duration}
      offset={offset}
      spy={spy}
      activeClass={activeClass}
      className="menu-link"
      onClick={() => handleClick()}
    >
      {/* <RedirectLink to={to} {...rest}> */}
      {children}
      {/* </RedirectLink> */}
    </Link>
  );
};
