import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

/**
 * Daily Darshan Skeleton
 */

export const DailyDarshanSkeleton = () => {
  let length = 5;
  let sekeleton = [];
  for (let i = 0; i < length; i++) {
    sekeleton.push(
      <div
        className="col-lg-2 col-md-4 col-6 daily-image"
        style={{
          boxShadow:
            "0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important",
        }}
      >
        <Skeleton
          height={465}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "7px",
          }}
        />
      </div>
    );
  }
  return sekeleton.length && sekeleton;
};

/**
 * image not found card
 * @returns image html
 */

export const ImageNotFound = () => {
  return (
    <div
      className="col-lg-2 col-md-4 col-6 daily-image"
      style={{
        boxShadow:
          "0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important",
        borderRadius: "7px",
      }}
    >
      <img
        src={
          "https://static.vecteezy.com/system/resources/previews/005/337/799/large_2x/icon-image-not-found-free-vector.jpg"
        }
        alt={"not found"}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "7px",
        }}
      />
    </div>
  );
};

/**
 * youtube link id return func
 * @param {youtube link} uri
 * @returns youtube link id
 */

export const YoutubeUrl = (uri) => {
  let k = uri.split("/");
  let id = 0;
  if (k[2] === "www.youtube.com") {
    let link = uri.split("&");
    let splitId = link[0]?.split("v=");
    id = splitId[1];
  } else if (k[2] === "youtu.be") {
    let link = uri.split("youtu.be/");
    id = link[1];
  }
  return id;
};

/**
 * privar sabha skeleton
 * @returns parivar sabha skeleton html
 */

export const PrivarSabhaSkeleton = () => {
  let length = 5;
  let sekeleton = [];
  for (let i = 0; i < length; i++) {
    sekeleton.push(
      <div>
        <div style={{ width: "251.19px", height: "300px" }}>
          <Skeleton
            height={300}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <div style={{ marginTop: "15px" }}>
          <p
            style={{
              margin: "0",
              fontWeight: "400",
              fontSize: "0.875rem",
              lineHeight: "1.43",
              letterSpacing: "0.01071em",
              marginBottom: "0.35rem",
            }}
          >
            <Skeleton />
          </p>
          <span
            style={{
              margin: "0",
              fontWeight: "400",
              fontSize: "0.75rem",
              lineHeight: "1.66",
              letterSpacing: "0.03333em",
              display: "block",
              color: "rgba(0,0,0,0.6)",
              marginBottom: "0.35rem",
            }}
          >
            <Skeleton width={90} />
          </span>
          <span
            style={{
              margin: "0",
              fontWeight: "400",
              fontSize: "0.75rem",
              lineHeight: "1.66",
              letterSpacing: "0.03333em",
              display: "block",
              color: "rgba(0,0,0,0.6)",
              marginTop: "0.35rem",
            }}
          >
            <Skeleton width={150} />
          </span>
        </div>
      </div>
    );
  }
  return sekeleton.length && sekeleton;
};

export const EventSkeleton = () => {
  let length = 5;
  let sekeleton = [];
  for (let i = 0; i < length; i++) {
    sekeleton.push(
      <div>
        <div style={{ width: "311px", height: "207px" }}>
          <Skeleton height={207} width={311} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="button-rounded text-transform-none ls-0 fw-normal ms-0 mt-4">
            <Skeleton height={43.59} width={267} />
          </div>
        </div>
      </div>
    );
  }
  return sekeleton.length && sekeleton;
};

export const AartiSkeleton = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "20px",
        }}
      >
        <p
          style={{
            fontWeight: "400",
            fontSize: "0.875rem",
            lineHeight: "1.43",
            letterSpacing: "0.01071em",
            display: "flex",
            alignItems: "center",
            margin: "0px",
          }}
        >
          <Skeleton height={25} width={200} />
        </p>
        <span
          style={{
            margin: "0",
            fontWeight: "400",
            fontSize: "0.75rem",
            lineHeight: "1.66",
            letterSpacing: "0.03333em",
            display: "flex",
            alignItems: "center",
            color: "rgba(0,0,0,0.6)",
            marginTop: "0.35rem",
          }}
        >
          <Skeleton height={25} width={175} />
        </span>
      </div>
      <Skeleton height={480} />
    </>
  );
};

/**
 * images skeleton
 */

export const ImagesSkeleton = ({ load }) => {
  return (
    <Skeleton
      height={150}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "7px",
        display: load === true ? "none" : "block",
      }}
    />
  );
};

/**
 *
 * @param {page Number , total number of record , perpage number , page number chnage ,next page function} param0
 * @returns buttons
 */

export const PaginationButtons = ({
  page,
  totalRecords,
  perPage,
  setPage,
  nextPage,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <div style={{ paddingBottom: "20px" }}>
        <button
          className="button button-rounded button-border text-transform-none ls-0 fw-normal ms-0 mt-4 btn-hover-effect"
          onClick={() => {
            setPage(page - 1);
          }}
          style={{
            color: page <= 1 ? "rgb(149 116 114)" : "#ff3d32",
            borderColor: page <= 1 ? "rgb(149 116 114)" : "#ff3d32",
          }}
          disabled={page <= 1}
        >
          {"Previus"}
        </button>
      </div>
      <div style={{ paddingBottom: "20px" }}>
        <button
          className="button button-rounded button-border text-transform-none ls-0 fw-normal ms-0 mt-4 success-btn-hover-effect"
          onClick={() => {
            nextPage();
          }}
          style={{
            color:
              page + 1 > Math.ceil(totalRecords / perPage)
                ? "rgb(114 160 131 / 93%)"
                : "#22c55eed",
            borderColor:
              page + 1 > Math.ceil(totalRecords / perPage)
                ? "rgb(114 160 131 / 93%)"
                : "#22c55e",
          }}
          disabled={page + 1 > Math.ceil(totalRecords / perPage)}
        >
          {"Next"}
        </button>
      </div>
    </div>
  );
};
