import React, { useContext } from 'react';
import volunteers from '../../nonprofit/images/icons/volunteers.svg';
import educated from '../../nonprofit/images/icons/educated.svg';
import { useNavigate } from 'react-router-dom';
import ApiCallsContext from '../../context/ApisCallsContext/ApiCallsContext';
import AllSatesContext from '../../context/allStates/AllStatesContext';

const Activitis = () => {
  const { activityData } = useContext(ApiCallsContext);

  const { setActivityMasterId } = useContext(AllSatesContext);
  const history = useNavigate();

  const handleRedirect = (type, activityMasterId) => {
    setActivityMasterId(activityMasterId);
    localStorage.setItem('activityMasterId', activityMasterId);
    switch (type) {
      case 2:
        history('/videos');
        break;
      case 1:
        history('/gallery');
        break;
    }
  };

  return (
    <div className="container-fluid my-5" id="activitis">
      <div className="d-flex flex-column align-items-center justify-content-center text-center counter-section position-relative py-5 overmissoun">
        <div className="mx-auto text-center" style={{ maxWidth: '1000px' }}>
          <h3>Activitis</h3>
        </div>

        <div className="row align-items-stretch m-0 w-100">
          {activityData?.length
            ? activityData?.map((elem) => {
                return (
                  <div
                    className="col-lg-6 col-sm-6 text-center mt-5"
                    onClick={() => handleRedirect(elem.type, elem.activityMasterId)}
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      src={elem.type === 2 ? educated : volunteers}
                      // src={breakfast}
                      alt="Counter Icon"
                      width="70"
                      className="mb-4"
                    />
                    <div className="counter font-secondary">
                      <span
                        data-from="100"
                        data-to="11265"
                        data-refresh-interval="50"
                        data-speed="2100"
                        data-comma="true"
                      ></span>
                      +
                    </div>

                    <h5 className="text-transform-none ls-0 mt-0">
                      <u>{elem.name}</u>
                    </h5>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
    </div>
  );
};

export default Activitis;
