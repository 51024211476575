import React, { Fragment, useContext, useMemo, useState } from "react";
import { ImagesArr, imageUri } from "../../constant";
import Contentheader from "../../reuse/Contentheader";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ApiCallsContext from "../../context/ApisCallsContext/ApiCallsContext";
import "react-loading-skeleton/dist/skeleton.css";
import { DailyDarshanSkeleton } from "../../utils/helper";
import DailydarshanSlider from "./DailydarshanSlider";

const DailyDarshan = (props) => {
  const [indexOfImages, setIndexOfImages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalBagasara, setShowModalBagasara] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    dailyDarsanKhopala,
    dailyDarsanBagasara,
    dailyDarshanDate,
    setDailyDarshanDate,
    dailyDarshanBagasara,
    dailyDarshanKhopala,
    showLoader,
  } = useContext(ApiCallsContext);

  const openModalAndSetIndex = (index) => {
    setIndexOfImages(index);
    setShowModal(true);
  };

  const openModalAndSetIndexBagasara = (index) => {
    setIndexOfImages(index);
    setShowModalBagasara(true);
  };

  let imgsContentKhopala = useMemo(() => {
    if (dailyDarsanKhopala) {
      return dailyDarsanKhopala?.map((elem) => {
        return imageUri + "/" + elem.link;
      });
    }
  }, [dailyDarsanKhopala]);

  let imgsContentBagasara = useMemo(() => {
    if (dailyDarsanBagasara) {
      return dailyDarsanBagasara?.map((elem) => {
        return imageUri + "/" + elem.link;
      });
    }
  }, [dailyDarsanBagasara]);

  const handleOnLoad = () => {
    setLoading(false);
  };

  console.log("dailyDarshanDate >>", dailyDarshanDate);

  return (
    <Fragment>
      <div className="section bg-transparent" style={{ margin: "0" }} id="22">
        <div className="container">
          <Contentheader header={"Daily Darshan / Bagasara"} />
          <div className="d-flex justify-content-start mb-3 darshan-date">
            <input
              type="date"
              value={dailyDarshanDate.bagasaraDate}
              onChange={(e) => {
                setDailyDarshanDate((prev) => {
                  return {
                    ...prev,
                    bagasaraDate: e.target.value,
                  };
                });
                dailyDarshanBagasara(e.target.value);
              }}
            />
          </div>
          <div className="row justify-content-around" style={{ gap: "20px" }}>
            {dailyDarsanBagasara?.length || loading ? (
              dailyDarsanBagasara?.map((elem, index) => (
                <div
                  className="col-lg-2 col-md-4 col-6 daily-image"
                  style={{
                    boxShadow:
                      "0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important",
                    borderRadius: "7px",
                  }}
                  onClick={() => openModalAndSetIndexBagasara(index)}
                >
                  <img
                    src={
                      imageUri + "/" + elem.link ||
                      "https://static.vecteezy.com/system/resources/previews/005/337/799/large_2x/icon-image-not-found-free-vector.jpg"
                    }
                    alt={elem.alt}
                    onLoad={handleOnLoad}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "7px",
                      display: loading ? "none" : "block",
                    }}
                  />
                  <span className="name">{elem.name}</span>
                </div>
              ))
            ) : showLoader ? (
              <DailyDarshanSkeleton />
            ) : dailyDarsanBagasara?.length === 0 ? (
              <div
                style={{
                  height: "10rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1>Data Not Found</h1>
              </div>
            ) : (
              ""
            )}
            {loading && <DailyDarshanSkeleton />}
          </div>
        </div>
        <div className="container" style={{ paddingTop: "30px" }}>
          <Contentheader header={"Daily Darshan / Khopala"} />
          <div className="d-flex justify-content-start mb-3 darshan-date">
            <input
              type="date"
              value={dailyDarshanDate.khopalaDate}
              onChange={(e) => {
                setDailyDarshanDate((prev) => {
                  return {
                    ...prev,
                    khopalaDate: e.target.value,
                  };
                });
                dailyDarshanKhopala(e.target.value);
              }}
            />
          </div>
          <div className="row justify-content-around" style={{ gap: "20px" }}>
            {dailyDarsanKhopala?.length || showLoader ? (
              dailyDarsanKhopala?.map((elem, index) => {
                return (
                  <>
                    <div
                      className="col-lg-2 col-md-4 col-6 daily-image"
                      style={{
                        boxShadow:
                          "0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important",
                        borderRadius: "7px",
                      }}
                      onClick={() => openModalAndSetIndex(index)}
                    >
                      <img
                        src={
                          imageUri + "/" + elem.link ||
                          "https://static.vecteezy.com/system/resources/previews/005/337/799/large_2x/icon-image-not-found-free-vector.jpg"
                        }
                        alt={elem.alt}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "7px",
                        }}
                      />
                      <span className="name">{elem.name}</span>
                    </div>
                  </>
                );
              })
            ) : showLoader ? (
              <DailyDarshanSkeleton />
            ) : dailyDarsanKhopala?.length === 0 ? (
              <div
                style={{
                  height: "10rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1>Data Not Found</h1>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <DailydarshanSlider
        showModal={showModal}
        indexOfImages={indexOfImages}
        setShowModal={setShowModal}
        imgsContent={imgsContentKhopala}
        setIndexOfImages={setIndexOfImages}
      />
      <DailydarshanSlider
        showModal={showModalBagasara}
        indexOfImages={indexOfImages}
        setShowModal={setShowModalBagasara}
        imgsContent={imgsContentBagasara}
        setIndexOfImages={setIndexOfImages}
      />
    </Fragment>
  );
};

export default DailyDarshan;
