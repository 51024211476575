import React from "react";
import Contentheader from "../../reuse/Contentheader";

const JoinUs = () => {
  return (
    <div className="section bg-transparent mt-0 mb-4" id="joinus">
      <div className="container">
        <Contentheader header="Join Us" />
        <div className="d-flex justify-content-around  ">
          <a
            href="https://joinus.bagasaradham.org/bagasara/swaminarayan-mandir-bagasara/temples"
            target="_blank"
            rel="noreferrer"
            className="button button-rounded button-border text-transform-none ls-0 fw-normal ms-0 mt-4 "
          >
            {" "}
            Bagasara Dham
          </a>
          <a
            href="https://joinus.bagasaradham.org/bagasara/khopaladham/temples"
            target="_blank"
            rel="noreferrer"
            className="button button-rounded button-border text-transform-none ls-0 fw-normal ms-0 mt-4 "
          >
            {" "}
            Khopala Dham
          </a>
          <a
            href="https://joinus.bagasaradham.org/bagasara/motavarachha-surat/temples"
            target="_blank"
            rel="noreferrer"
            className="button button-rounded button-border text-transform-none ls-0 fw-normal ms-0 mt-4 "
          >
            {" "}
            Rajhans Tower Surat
          </a>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
