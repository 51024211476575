import React, { useState } from "react";
import ApiCallsContext from "./ApiCallsContext";
import {
  EvnetsApi,
  activityesApi,
  activityesMasterApi,
  dailyDarshanApi,
  dashboardApi,
  parivarShabhaApi,
  subActivityesApi,
  subToSubActivityesApi,
} from "../../apis/AllApis/AllApis";
import { Temple, type } from "../../constant";
import moment from "moment";

const ApiCallsState = (props) => {
  const [formValue, setFormValue] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [liveDarshanData, setLiveDarshanData] = useState([]);
  const [dailyDarsanData] = useState([]);
  const [dailyDarsanKhopala, setDailyDarshanKhopala] = useState([]);
  const [dailyDarsanBagasara, setDailyDarshanBagasara] = useState([]);
  const [parivarShabhaData, setParivarShabhaData] = useState([]);
  const [eventMaster, setEventMaster] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [activityChildData, setActivityChildData] = useState([]);
  const [subActivitys, setSubActivitys] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [subToSubActivitys, setSubToSubActivitys] = useState([]);
  const [id, setId] = useState("");
  const [dailyDarshanDate, setDailyDarshanDate] = useState({
    bagasaraDate: "",
    khopalaDate: "",
  });
  const [darshanLoader, setDarshanLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  /**
   * pagination states
   */

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const deshboard = async () => {
    setShowLoader(true);
    setDarshanLoader(true);
    try {
      const { data } = await dashboardApi();
      if (data.status === 200) {
        setFormValue(data.payload);
        setSliderData(data.payload.find((elem) => elem.sliderDetail));
        setLiveDarshanData(data.payload.find((elem) => elem.liveDarshan));
        setDailyDarshanKhopala(
          data.payload.find((elem) => elem.dailyDarshanKhopala)
            .dailyDarshanKhopala
        );
        setDailyDarshanBagasara(
          data.payload.find((elem) => elem.dailyDarshanBagasara)
            .dailyDarshanBagasara
        );
        // setParivarShabhaData(data.payload.find((elem) => elem.parivarSabha));
        setEventMaster(data.payload.find((elem) => elem.eventMaster));
        setDarshanLoader(false);
        setShowLoader(false);
      } else {
        setDarshanLoader(false);
        setShowLoader(false);
      }
    } catch (error) {
      setDarshanLoader(false);
      setShowLoader(false);
    }
  };

  const dailyDarshanBagasara = async (date) => {
    try {
      setDarshanLoader(true);
      let paramObj = {
        search: {
          type: type.Image,
          templeId: Temple.Bagasara,
        },
        recordsPerPage: 5,
      };
      if (date) {
        paramObj["search"]["date"] = date;
      }
      const { data } = await dailyDarshanApi({ paramObj });
      if (data.status === 200) {
        setDailyDarshanBagasara(data.payload.data);
        setDarshanLoader(false);
      } else {
      }
    } catch (error) {
      setDarshanLoader(false);
      setShowLoader(false);
    }
  };
  const dailyDarshanKhopala = async (date) => {
    try {
      setDarshanLoader(true);
      let paramObj = {
        search: {
          type: type.Image,
          templeId: Temple.Khopala,
        },
        recordsPerPage: 5,
      };

      if (date) {
        paramObj["search"]["date"] = date;
      }
      const { data } = await dailyDarshanApi({ paramObj });
      if (data.status === 200) {
        setDailyDarshanKhopala(data.payload.data);
        setDarshanLoader(false);
      } else {
      }
    } catch (error) {
      setDarshanLoader(false);
      setShowLoader(false);
    }
  };

  const PrivarSabha = async () => {
    setShowLoader(true);
    let paramObj = {
      sortField: "date",
      sortOrder: "ASC",
    };
    try {
      const { data } = await parivarShabhaApi(paramObj);
      if (data.status === 200) {
        setParivarShabhaData(data.payload.data);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const activitysMaster = async () => {
    setShowLoader(true);
    try {
      const { data } = await activityesMasterApi();
      if (data.status === 200) {
        setActivityData(data.payload.data);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const activitys = async (activityMasterId) => {
    setShowLoader(true);
    let paramsObj = {
      search: JSON.stringify({ activityMasterId: activityMasterId }),
    };
    try {
      const { data } = await activityesApi(paramsObj);
      if (data.status === 200) {
        setActivityChildData(
          data.payload.data.map((v) => ({ ...v, isActive: false }))
        );
        setId(data.payload.data[0].activityId);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const sub_Activitys = async (activityId) => {
    setShowLoader(true);
    let paramObj = {
      pageNumber: page,
      recordsPerPage: perPage,
      search: JSON.stringify({
        activityId: activityId ? activityId : id,
      }),
    };
    try {
      const { data } = await subActivityesApi(paramObj);
      if (data.status === 200) {
        setSubActivitys(data.payload.data);
        setTotalRecords(data.pager.totalRecords);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const sub_to_sub_Activitys = async (subActivityId) => {
    setShowLoader(true);
    let paramObj = {
      pageNumber: page,
      recordsPerPage: perPage,
      search: JSON.stringify({
        subActivityId: subActivityId,
      }),
    };
    try {
      const { data } = await subToSubActivityesApi(paramObj);
      if (data.status === 200) {
        setSubToSubActivitys(data.payload.data);
        setTotalRecords(data.pager.totalRecords);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const Events_Api = async (eventMasterId) => {
    setShowLoader(true);
    let paramObj = {
      search: JSON.stringify({
        eventMasterId: eventMasterId,
      }),
      pageNumber: page,
      recordsPerPage: perPage,
    };
    try {
      const { data } = await EvnetsApi(paramObj);
      if (data.status === 200) {
        setEventData(data.payload.data);
        setTotalRecords(data.pager.totalRecords);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  return (
    <ApiCallsContext.Provider
      value={{
        formValue,
        setFormValue,
        deshboard,
        sliderData,
        liveDarshanData,
        dailyDarsanData,
        parivarShabhaData,
        eventMaster,
        activityData,
        activitysMaster,
        activityChildData,
        setActivityChildData,
        activitys,
        subActivityesApi,
        sub_Activitys,
        subActivitys,
        id,
        sub_to_sub_Activitys,
        subToSubActivitys,
        perPage,
        setPerPage,
        page,
        setPage,
        totalRecords,
        eventData,
        Events_Api,
        showLoader,
        PrivarSabha,
        setTotalRecords,
        dailyDarsanKhopala,
        setDailyDarshanKhopala,
        dailyDarsanBagasara,
        setDailyDarshanBagasara,
        dailyDarshanBagasara,
        dailyDarshanKhopala,
        dailyDarshanDate,
        setDailyDarshanDate,
        darshanLoader,
        setDarshanLoader,
      }}
    >
      {props.children}
    </ApiCallsContext.Provider>
  );
};

export default ApiCallsState;
