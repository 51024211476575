import React, { useContext, useEffect } from "react";
import Contentheader from "../../../reuse/Contentheader";
import Videos from "./Videos";
import { useNavigate } from "react-router-dom";
import BackImage from "../../../reuse/backButton";
import AllSatesContext from "../../../context/allStates/AllStatesContext";
import ApiCallsContext from "../../../context/ApisCallsContext/ApiCallsContext";
import { PaginationButtons } from "../../../utils/helper";

const Video = (props) => {
  const { setProgress } = props;
  const { formValue } = useContext(AllSatesContext);

  const {
    activityChildData,
    setActivityChildData,
    sub_Activitys,
    page,
    totalRecords,
    perPage,
    setPage,
  } = useContext(ApiCallsContext);

  const history = useNavigate();

  useEffect(() => {
    setProgress(100);
    // eslint-disable-next-line
  }, []);

  const handleClick = (index, activityId) => {
    setActivityChildData(
      activityChildData.map((elem, menuIndex) => {
        if (menuIndex === index && elem.isActive === false) {
          elem.isActive = true;
          sub_Activitys(activityId);
        } else {
          elem.isActive = false;
        }
        return elem;
      })
    );
  };
  const handlePrev = () => {
    history("/");
  };

  const nextPage = () => {
    if (page + 1 > Math.ceil(totalRecords / perPage)) {
    } else {
      setPage(page + 1);
    }
  };
  return (
    <div>
      <Contentheader header={"Videos"} />
      <BackImage redirect={handlePrev} />
      {activityChildData.map((elem, index) => {
        return (
          <button
            className={`button button-rounded button-border text-transform-none ls-0 fw-normal ms-0 mt-4 
            `}
            key={index}
            onClick={() => handleClick(index, elem.activityId)}
            style={
              elem.isActive === true
                ? {
                    background: "#c6c09c",
                    color: "white",
                    border: "1px solid rgb(151 143 95);",
                  }
                : null
            }
          >
            {elem.name}
          </button>
        );
      })}
      <Videos formValue={formValue} />
      <PaginationButtons
        page={page}
        totalRecords={totalRecords}
        perPage={perPage}
        setPage={setPage}
        nextPage={nextPage}
      />
    </div>
  );
};

export default Video;
