import React, { useContext, useEffect } from 'react';
import AllSatesContext from '../../../context/allStates/AllStatesContext';
import { useNavigate } from 'react-router-dom';
import { YoutubeUrl } from '../../../utils/helper';
import { toutubeUrl } from '../../../constant';
import ApiCallsContext from '../../../context/ApisCallsContext/ApiCallsContext';

const Videos = () => {
  const { setEventsName, setVideoAll, activityMasterId, setSubActivityId } = useContext(AllSatesContext);
  const { activitys, sub_Activitys, subActivitys, id, setPerPage, setPage, setTotalRecords, page } =
    useContext(ApiCallsContext);

  const history = useNavigate();

  const hanldeRedirect = ({ name, link, subActivityId }) => {
    setEventsName(name);
    setSubActivityId(subActivityId);
    localStorage.setItem('subActivityId', subActivityId);
    const id = YoutubeUrl(link);
    setVideoAll(toutubeUrl + '/' + id);
    localStorage.setItem('youtubeLink', toutubeUrl + '/' + id);
    history('/videos/gallery');
  };

  useEffect(() => {
    activitys(activityMasterId || localStorage.getItem('activityMasterId'));
    localStorage.removeItem('subActivityId');
    localStorage.removeItem('youtubeLink');
    sub_Activitys();
  }, [id, page]);
  useEffect(() => {
    setPerPage(10);
    setPage(1);
    setTotalRecords(0);
  }, []);
  return (
    <div className="row justify-content-around" style={{ gap: '30px', paddingTop: '30px' }}>
      {subActivitys.length &&
        subActivitys?.map((elem) => {
          const id = YoutubeUrl(elem?.link);
          return (
            <div
              className="col-lg-2 col-md-4 col-6 mb-6 image-div"
              onClick={() =>
                hanldeRedirect({
                  name: elem.name,
                  link: elem.link,
                  subActivityId: elem.subActivityId
                })
              }
            >
              <div className="team overflow-hidden">
                <div className="team-image">
                  <img src={`https://i.ytimg.com/vi/${id}/hqdefault.jpg`} alt="Penny Tool" />
                </div>
                <div className="team-desc">
                  <h4
                    className="pt-3 mb-0 fw-medium text-transform-none"
                    style={{ color: '#746e6e', fontSize: '12px' }}
                  >
                    {elem.name}
                  </h4>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Videos;
