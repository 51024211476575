import React from "react";
import Contentheader from "../../reuse/Contentheader";

const AboutUs = () => {
  const p_graph = `Shree Swaminarayan Temple, Tirthdham Bagasara is a Spiritual Organization
  Which falls under the region Of Vadtal Temple (The Headquarter Of
  Laxminarayan Dev Gadi). It is the First Organization Which is
  Constructed By Pink Stone With Beautiful and Marvellous Carving in
  Surat Of Swaminarayan Sampraday, Vadtal desh Which is increasing
  Pride & Prestige of Surat City, This temple contains One Main
  Shrine under which the idol of Ladila Shree Ghanshyam Maharaj is
  installed. P. Pu. Sadguru Shree Laxmiprasad Dasji Swami is the
  Creator of this divine Organization. By the Several Activities &
  appearance Many devotees feels Easy in difficult Circumstances. He
  with his approx. 15 Saints & Devotees does Several Social &
  Spiritual Activities to fulfill the goal of Swaminarayan
  Sampraday, to do Worship with religious practices.`;
  return (
    <div className="section bg-transparent mt-0 mb-4" id="about">
      <div className="container">
        <Contentheader header="About Us" p_graph={p_graph} />
      </div>
    </div>
  );
};

export default AboutUs;
