import axios from "axios";
import {
  ACTIVITYS_API,
  ACTIVITYS_MASTER_API,
  DAILY_DARSHAN,
  DESHBOARD,
  EVENT_API,
  EVNTES_API,
  PARIVAR_SABHA,
  SUB_ACTIVITYS_API,
  SUB_TO_SUB_ACTIVITYS_API,
} from "../../constant/ApiConstant";

export const dashboardApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}${DESHBOARD}`);
};

export const dailyDarshanApi = ({ paramObj }) => {
  let newParamObj = {
    ...paramObj,
    search: JSON.stringify({ ...paramObj.search }),
  };
  return axios.get(`${process.env.REACT_APP_API_URL}${DAILY_DARSHAN}`, {
    params: newParamObj,
  });
};

export const parivarShabhaApi = (paramsObj) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${PARIVAR_SABHA}`, {
    params: paramsObj,
  });
};

export const eventApi = (paramObj) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${EVENT_API}`, {
    params: paramObj,
  });
};

export const activityesMasterApi = (paramObj) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${ACTIVITYS_MASTER_API}`, {
    params: paramObj,
  });
};

export const activityesApi = (paramObj) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${ACTIVITYS_API}`, {
    params: paramObj,
  });
};

export const subActivityesApi = (paramObj) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${SUB_ACTIVITYS_API}`, {
    params: paramObj,
  });
};

export const EvnetsApi = (paramObj) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${EVNTES_API}`, {
    params: paramObj,
  });
};

export const subToSubActivityesApi = (paramObj) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${SUB_TO_SUB_ACTIVITYS_API}`,
    {
      params: paramObj,
    }
  );
};
