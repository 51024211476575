import React, { useEffect } from "react";
import Contentheader from "../../reuse/Contentheader";
import { OurSaintImagesAndName } from "../../constant";

const OurSaint = (props) => {
  const { setProgress } = props;
  useEffect(() => {
    setProgress(100);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Contentheader header="Our Saint" />
      {OurSaintImagesAndName.map((elem) => {
        return (
          <div
            className="row hoverEffect"
            style={{
              padding: "30px",
              border: "1px solid #dee2e6",
              borderRadius: "12px",
              marginTop: "20px",
            }}
          >
            <div className="col-sm-4">
              <img
                src={elem.src}
                alt=""
                style={{
                  display: "block",
                  maxWidth: "100%",
                  height: "auto",
                  marginLeft: "0px",
                  border: "0",
                }}
              />
            </div>
            <div className="col-sm-8">
              <h4 style={{ marginTop: "20px" }}>{elem.name}</h4>
              <p style={{ textAlign: "justify" }}>{elem.para}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OurSaint;
