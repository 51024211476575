import React from "react";

const ContactUs = () => {
  return (
    <>
      <div className="line line-sm my-0"></div>
      <div
        className="section section-details mb-0 bg-white"
        style={{ padding: "80px 0 160px" }}
        id="contact"
      >
        <div
          className="w-100 h-100 d-none d-md-block"
          // style="position: absolute; top: 0; left: 0; background: #FFF url('nonprofit/images/others/6.jpg') no-repeat bottom right / cover;"
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-md-4 px-4 mb-5">
              <h4 className="fw-medium mb-4">Address</h4>
              <p className="mb-3">Kunkavav Road, Bagasara, Gujarat 365440</p>
              <abbr title="Location">
                <strong>Location:</strong>
              </abbr>
              <a
                href="https://goo.gl/maps/GFW2UQVHoanvNQkv6"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                View on Map
              </a>
              <br />
              <abbr title="Phone Number">
                <strong>Phone:</strong>
              </abbr>{" "}
              (+91) 90999 97260, 90999 97255
              <br />
              <abbr title="Email Address">
                <strong>Email:</strong>
              </abbr>{" "}
              bagasaradham2024@gmail.com
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "35px",
                }}
              >
                <img
                  src="https://mcode.fra1.digitaloceanspaces.com/byte-qr-nfc/orgPageQrCode/bagasara-P176.png"
                  alt=""
                  width={200}
                />
              </div>
              <br />
              <abbr title="visit">
                <strong>Join Us:</strong>
              </abbr>
              <a
                href="https://joinus.bagasaradham.org"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Show Qr Details
              </a>
            </div>

            <div className="col-md-4 px-4 mb-5">
              <h4 className="fw-medium mb-4">Facebook</h4>
              <iframe
                src="https://www.facebook.com/plugins/page?href=https://www.facebook.com/Bagasarakatha?ref=pages_you_manage&amp;tabs=timeline&amp;height=550"
                width="100%"
                style={{ border: "none", overflow: "hidden", height: "25rem" }}
                scrolling="no"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
                className="setheight"
                title="facebook"
              />
            </div>

            <div className="col-md-4 px-4 mb-5">
              <h4 className="fw-medium mb-4">Instagram</h4>
              <iframe
                className="instagram-media instagram-media-rendered setheight"
                id="instagram-embed-0"
                src="https://www.instagram.com/tirthdham_bagasara/embed/?hl=en&amp;v=12&amp;wp=326&amp;"
                style={{ border: "none", overflow: "hidden", height: "25rem" }}
                allowtransparency="true"
                allowFullScreen="true"
                frameBorder="0"
                data-instgrm-payload-id="instagram-media-payload-0"
                scrolling="no"
                width="100%"
                title="instagram"
              ></iframe>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7424.907718906977!2d70.9558726!3d21.4899356!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be29d03b3ed6d29%3A0xc07cdca0ddc5ec9f!2sShree%20Swaminarayan%20Mukhya%20Mandir!5e0!3m2!1sen!2sin!4v1685074915918!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="location"
            ></iframe>

            {/* <div className="col-md-4 px-4 mb-5 mb-md-0">
            <h4 className="fw-medium mb-4">Support</h4>
            <p className="mb-3">
              Compellingly enable premium alignments rather than sustainable
              content.
            </p>
            <a href="#" className="mb-1 d-block">
              <i
                className="bi-envelope position-relative"
                style={{ top: "1px" }}
              ></i>{" "}
              no.reply@canvas.com
            </a>
            <div className="fw-medium mb-2 d-block">
              Monday - Friday 10:30 -18:00
            </div>
            <a
              href="#"
              className="social-icon bg-dark si-small h-bg-facebook"
              title="Facebook"
            >
              <i className="fa-brands fa-facebook-f"></i>
              <i className="fa-brands fa-facebook-f"></i>
            </a>

            <a
              href="#"
              className="social-icon bg-dark si-small h-bg-delicious"
              title="Delicious"
            >
              <i className="fa-brands fa-delicious"></i>
              <i className="fa-brands fa-delicious"></i>
            </a>

            <a
              href="#"
              className="social-icon bg-dark si-small h-bg-paypal"
              title="Paypal"
            >
              <i className="fa-brands fa-paypal"></i>
              <i className="fa-brands fa-paypal"></i>
            </a>

            <a
              href="#"
              className="social-icon bg-dark si-small h-bg-waze"
              title="Flattr"
            >
              <i className="fa-brands fa-waze"></i>
              <i className="fa-brands fa-waze"></i>
            </a>

            <a
              href="#"
              className="social-icon bg-dark si-small h-bg-android"
              title="Android"
            >
              <i className="fa-brands fa-android"></i>
              <i className="fa-brands fa-android"></i>
            </a>

            <a
              href="#"
              className="social-icon bg-dark si-small h-bg-discord"
              title="Smashmag"
            >
              <i className="fa-brands fa-discord"></i>
              <i className="fa-brands fa-discord"></i>
            </a>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
