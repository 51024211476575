import React, { useContext, useEffect, useState } from 'react';
import svg_divider1 from '../../../nonprofit/images/divider-1.svg';
import { imageUri } from '../../../constant';
import { useNavigate } from 'react-router-dom';
import AllSatesContext from '../../../context/allStates/AllStatesContext';
import BackImage from '../../../reuse/backButton';
import ApiCallsContext from '../../../context/ApisCallsContext/ApiCallsContext';
import { ImagesSkeleton, PaginationButtons } from '../../../utils/helper';

const MoreImages = (props) => {
  const { setProgress } = props;
  const history = useNavigate();
  const { activityMasterId, setSubActivityId } = useContext(AllSatesContext);
  const {
    activitys,
    activityChildData,
    sub_Activitys,
    subActivitys,
    id,
    page,
    setPage,
    totalRecords,
    setTotalRecords,
    perPage,
    setPerPage
  } = useContext(ApiCallsContext);

  const [load, setLoad] = useState(false);

  const hanldeRedirect = (subActivityId) => {
    setSubActivityId(subActivityId);
    localStorage.setItem('subActivityId', subActivityId);
    history('/gallery/images');
  };

  const handlePrev = () => {
    history('/');
  };

  useEffect(() => {
    activitys(activityMasterId || localStorage.getItem('activityMasterId'));
    localStorage.removeItem('subActivityId');
    setProgress(100);
    sub_Activitys();
    // eslint-disable-next-line
  }, [id, page]);

  useEffect(() => {
    setPerPage(10);
    setPage(1);
    setTotalRecords(0);
  }, []);

  const handleClick = (activityId) => {
    setLoad(false);
    sub_Activitys(activityId);
  };

  const nextPage = () => {
    if (page + 1 > Math.ceil(totalRecords / perPage)) {
    } else {
      setPage(page + 1);
    }
  };
  return (
    <>
      <div className="section bg-transparent mt-0 mb-4" id="event">
        <div className="container">
          <div className="row justify-content-center" style={{ marginTop: '100px' }}>
            <div className="col-md-7 text-center">
              <div className="heading-block border-bottom-0 mb-4">
                <h2 className="mb-4 text-transform-none">Photo Gallery</h2>
              </div>
              <div className="svg-line mb-4">
                <img src={svg_divider1} alt="svg divider" height="20" />
              </div>

              {activityChildData.map((elem) => {
                return (
                  <button
                    className="button button-rounded button-border text-transform-none ls-0 fw-normal ms-0 mt-4"
                    onClick={() => handleClick(elem.activityId)}
                  >
                    {elem.name}
                  </button>
                );
              })}
            </div>
          </div>
          <BackImage redirect={handlePrev} />
          <div className="row justify-content-around" style={{ gap: '30px', paddingTop: '30px' }}>
            {subActivitys?.map((elem) => {
              return (
                <div
                  className="col-lg-2 col-md-4 col-6 mb-6 image-div"
                  onClick={() => hanldeRedirect(elem.subActivityId)}
                >
                  <div className="team overflow-hidden">
                    <div className="team-image">
                      <img
                        src={imageUri + '/' + elem.image}
                        alt="Penny Tool"
                        onLoad={() => setLoad(true)}
                        style={{ display: load === true ? 'block' : 'none' }}
                      />
                      <ImagesSkeleton load={load} />
                    </div>
                    <div className="team-desc">
                      <h4 className="team-title pt-3 mb-0 fw-medium text-transform-none">{elem.name}</h4>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <PaginationButtons
            page={page}
            totalRecords={totalRecords}
            perPage={perPage}
            setPage={setPage}
            nextPage={nextPage}
          />
        </div>
      </div>
      {/* <audio controls preload="none">
        <source
          src={require("../../../css/153050761304 Shikshpatri Shlok.mp3")}
          type="audio/ogg"
        />
        {"Your browser does not support the audio element."}
      </audio> */}
    </>
  );
};

export default MoreImages;
