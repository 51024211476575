import React from "react";

const BackImage = (props) => {
  const { redirect } = props;
  return (
    <div className="container" style={{ paddingBottom: "20px" }}>
      <button
        className="button button-rounded button-border text-transform-none ls-0 fw-normal ms-0 mt-4 btn-hover-effect"
        onClick={() => {
          redirect();
        }}
        style={{ color: "red", borderColor: "red" }}
      >
        {"Back"}
      </button>
    </div>
  );
};

export default BackImage;
