import React, { Fragment, useContext, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ApiCallsContext from "../../context/ApisCallsContext/ApiCallsContext";

const SliderSection = () => {
  const { sliderData, deshboard } = useContext(ApiCallsContext);

  useEffect(() => {
    deshboard();
    // eslint-disable-next-line
  }, []);

  let responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 1,
    },
  };

  return (
    <Fragment>
      <section
        className="slider-element dark swiper_wrapper slider-parallax"
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
        id="home"
      >
        <OwlCarousel
          items={4}
          margin={10}
          autoplay={true}
          nav
          loop
          autoHeight={true}
          responsive={responsive}
        >
          {sliderData?.sliderDetail?.map((elem) => {
            return (
              <div
                className="slider-item-maindiv "
                style={{
                  position: "relative",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <div
                  className="slider-items donor-img d-flex align-items-center rounded parallax mx-auto shadow-sm w-100"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={elem.image}
                    alt={elem.alt}
                    className="slider-items"
                    style={{
                      width: "100%",
                      height: "",
                      willChange: "transform",
                      transition: "transform 0.4s cubic-bezier(0, 0, 0, 1) 0s",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      </section>
    </Fragment>
  );
};

export default SliderSection;
