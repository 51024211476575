import React, { Fragment } from "react";
import useQuery from "../../constant";
import { useNavigate } from "react-router-dom";

const Opening = (props) => {
  let query = useQuery();
  const history = useNavigate();
  const handleOpen = () => {
    if (query.get("open") === "true") {
      history("/?open=false");
    }
  };
  return (
    <Fragment>
      {query.get("open") === "false" || query.get("open") === "true" ? (
        <Fragment>
          <div
            className={
              query.get("open") === "false"
                ? `leftcurtain-open`
                : query.get("open") === "true" && "leftcurtain"
            }
          >
            <img
              src={require("./frontcurtain.jpg")}
              alt=""
              className="left-img"
            />
          </div>
          <div
            className={
              query.get("open") === "false"
                ? `rightcurtain-open`
                : query.get("open") === "true" && "rightcurtain"
            }
          >
            <img
              src={require("./frontcurtain (1).jpg")}
              alt=""
              className="right-img"
            />
          </div>
          <a
            className={
              query.get("open") === "false"
                ? `rope-open`
                : query.get("open") === "true" && "rope"
            }
            onClick={() => handleOpen()}
          >
            <img src={require("./rope.png")} alt="" />
          </a>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default Opening;
