import React from "react";
import Lightbox from "react-image-lightbox";

const DailydarshanSlider = (props) => {
  const {
    showModal,
    indexOfImages,
    setShowModal,
    imgsContent,
    setIndexOfImages,
  } = props;
  return (
    showModal && (
      <Lightbox
        mainSrc={imgsContent[indexOfImages]}
        nextSrc={imgsContent[(indexOfImages + 1) % imgsContent.length]}
        prevSrc={
          imgsContent[
            (indexOfImages + imgsContent.length - 1) % imgsContent.length
          ]
        }
        onCloseRequest={() => setShowModal(false)}
        onMovePrevRequest={() =>
          setIndexOfImages(
            (indexOfImages + imgsContent.length - 1) % imgsContent.length
          )
        }
        onMoveNextRequest={() =>
          setIndexOfImages((indexOfImages + 1) % imgsContent.length)
        }
      />
    )
  );
};

export default DailydarshanSlider;
