import React, { Fragment, useContext, useEffect } from "react";
import Aarti from "../Aarti/Aarti";
import DailyDarshan from "../Dailydarshan/DailyDarshan";
import AboutUs from "../AboutUs/AboutUs";
import Activitis from "../Activitis/Activitis";
import Events from "../EventsSlider/Events";
import ParivarSabha from "../ParivarSabha/ParivarSabha";
import ApiCallsContext from "../../context/ApisCallsContext/ApiCallsContext";

import { Link } from "react-scroll";
import JoinUs from "../joinUs/JoinUs";

const HomeComp = (props) => {
  const { setProgress } = props;
  const {
    deshboard,
    activitysMaster,
    PrivarSabha,
    setPerPage,
    setPage,
    setTotalRecords,
  } = useContext(ApiCallsContext);

  useEffect(() => {
    deshboard();
    activitysMaster();
    PrivarSabha();
    localStorage.removeItem("activityMasterId");
    localStorage.removeItem("eventMasterId");
    setProgress(100);
    setPerPage(10);
    setPage(1);
    setTotalRecords(0);
    <Link to="daily-darshan" smooth spy />;
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Aarti />
      <DailyDarshan />
      {/* <ImageTemplate /> */}
      <ParivarSabha />
      <Events />
      <AboutUs />
      <Activitis />
      <JoinUs />
    </Fragment>
  );
};

export default HomeComp;
