import React, { useState } from "react";
import AllStatesContext from "./AllStatesContext";

const AllStates = (props) => {
  const [EventsName, setEventsName] = useState("");
  const [videoAll, setVideoAll] = useState({
    name: "",
    url: "",
    data: [],
  });

  const [eventMasterId, setEventMasterId] = useState("");
  const [activityMasterId, setActivityMasterId] = useState("");
  const [subActivityId, setSubActivityId] = useState("");
  const [formValue, setFormValue] = useState("");
  return (
    <AllStatesContext.Provider
      value={{
        setEventsName,
        EventsName,
        videoAll,
        setVideoAll,
        eventMasterId,
        setEventMasterId,
        formValue,
        setFormValue,
        activityMasterId,
        setActivityMasterId,
        subActivityId,
        setSubActivityId,
      }}
    >
      {props.children}
    </AllStatesContext.Provider>
  );
};

export default AllStates;
